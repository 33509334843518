import React, { useEffect } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css";
import Image from 'react-bootstrap/Image';
import { IconContext } from "react-icons";
import { AiFillHtml5 } from "react-icons/ai";
import { FaPython, FaReact, FaNodeJs, FaVuejs, FaAngular } from "react-icons/fa";
import { DiMongodb, DiDatabase } from "react-icons/di";
import { SiCss3, SiJavascript, SiJava, SiMysql, SiCplusplus, SiPhp, SiFirebase } from "react-icons/si";
import { FcAbout, FcHome, FcGraduationCap, FcBusinessman } from "react-icons/fc";
import { MdSchool } from "react-icons/md";
import { Container, Row, Col, Button, Alert, Breadcrumb, BreadcrumbItem, Card, Form, Navbar, Nav, NavDropdown, FormControl, Collapse, Accordion, AccordionCollapse, AccordionCollapseProps, AccordionToggle, AccordionToggleProps, Table, TableProps } from 'react-bootstrap';


function About(){
    useEffect(() => {
        Aos.init();
    }, []);
    return(
        <>  
            <IconContext.Provider value={{ className: "icons" }}>
                <div className="about-img">
                    <Container>
                        <div className="aboutme-content">
                            <Container>
                                <Row className="aboutme-row-one">

                                <Col>
                                    <div className="card-two" data-aos="fade-left" data-aos-duration="1500" data-aos-easing="ease-in-sine">
                                        <Card variant="primary" className="mb-3" bg="light" text="black">
                                            <div className="card-two-content">
                                                <Col xs={5} md={5}>
                                                    <Image src="me3.jpg" height="165px" width="130px" roundedCircle />
                                                </Col>
                                                <Card.Body>
                                                    <Card.Title>Stephen Schmitz</Card.Title>
                                                    <Card.Subtitle className="mb-2"><i>B.S. Computer Science   Concentration: IT</i></Card.Subtitle>
                                                    <Card.Text><FcGraduationCap /> Education: The University of North Carolina at Charlotte</Card.Text>
                                                    <Card.Text><FcHome />  Resides: Charlotte, NC</Card.Text>
                                                    <Card.Text><FcBusinessman />  Age: 20</Card.Text>
                                                </Card.Body>
                                            </div>
                                        </Card>
                                    </div>
                                </Col>

                                <Col>
                                    <div className="card-one" data-aos="fade-right" data-aos-duration="1500" data-aos-easing="ease-in-sine">
                                        <Card className="mb-3" bg="light" text="black">
                                            <Card.Title className="aboutme-title"><FcAbout /> About Me:</Card.Title>
                                            <Card.Text className="aboutme-text">Highly motivated and ambitious full-stack developer seeking a full-time position for the 2022 year.</Card.Text>
                                        </Card>
                                    </div>
                                </Col>
                                </Row>  
                            </Container>

                            <Row className="aboutme-row-two">
                                <Table striped bordered hover size="sm"  variant="light" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                                <thead>
                                    <tr>
                                        <th>Front-end</th>
                                        <th>Back-end</th>
                                        <th>Database</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><AiFillHtml5 />  HTML5</td>
                                        <td><SiJava />  Java</td>
                                        <td><DiDatabase />  SQL</td>
                                    </tr>
                                    <tr>
                                        <td><SiCss3 />  CSS3</td>
                                        <td><SiCplusplus />  C++</td>
                                        <td><SiMysql />  MySQL</td>
                                    </tr>
                                    <tr>
                                        <td><SiJavascript />  Javascript</td>
                                        <td><FaPython />  Python</td>
                                        <td><DiMongodb />  MongoDB</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1"><div className="frameworks"><FaReact /> <FaNodeJs />  <FaVuejs /> <FaAngular /></div></td>
                                        <td><SiPhp />  PHP</td>
                                        <td><SiFirebase />  Firebase</td>
                                    </tr>
                                </tbody>
                                </Table>
                            </Row>
                        </div>
                    </Container>
                </div>
            </IconContext.Provider>
        </>
    )
}

export default About;