import React from 'react';
import Typed from 'react-typed';

const Headertext = () => {
    return(
      <div className="Header-wrapper">
        <div className="main-info">
          <h1>Stephen Schmitz Resume Site</h1>
          <Typed 
            className="typed-text"
            strings={["Front-end", "Back-end", "Database", "Full-Stack"]}
            typeSpeed={40}
            backSpeed={60}
            loop
          />
        </div>
      </div>
    )
}

export default Headertext;
